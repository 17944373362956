import { WppCard } from '@wppopen/components-library-react';
import React, { useRef } from 'react';
import { BoxContent } from '../../../shared/components';
import { DSInput } from '../../../shared/DSComponents';
import { translate } from '../../../shared/locales/i18n';
import { StatusEnum } from '../../../shared/types';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import UpdatedCardInfo from '../../UpdatedInfo/views/UpdatedCardInfo';
import { useBrandValueChangesState } from '../hooks';
import { isStringNotEmpty } from '../utils/brandPageUtils';
import SendToCmsCard from './SendToCmsCard';
import { BrandFallbackInput, MainContentContainer } from './styles/BrandPage.styled';

export interface ITabDetailsProps {
  newBrandTitle: string;
  brandUrl: string | undefined;
  hasErrorMessage: boolean;
  setNewBrandTitle: (brandTitle: string) => void;
  setBrandUrl: (brandFallback: string | undefined) => void;
  setHasErrorMessage: (hasErrorMessage: boolean) => void;
}

const TabDetails: React.FC<ITabDetailsProps> = ({
  newBrandTitle,
  brandUrl,
  hasErrorMessage,
  setNewBrandTitle,
  setBrandUrl,
  setHasErrorMessage,
}) => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setBrandValueChanges } = useBrandValueChangesState();

  const isCreateMode = !!(selectedSecondTierItem && selectedSecondTierItem?.status === StatusEnum.UNPUBLISHED);

  const titleInputHasChanged = useRef(false);
  const urlInputHasChanged = useRef(false);
  const titleNotEmpty = useRef(false);

  const handleInputChange = (title: string) => {
    setNewBrandTitle(title);
    titleNotEmpty.current = isStringNotEmpty(title);

    if (isCreateMode) {
      setBrandValueChanges(titleNotEmpty.current);
    } else {
      const isTitleDifferentAsBefore = title !== selectedSecondTierItem?.name;
      titleInputHasChanged.current = titleNotEmpty.current && isTitleDifferentAsBefore;
    }
  };

  const handleBrandWebsiteChange = (url: string) => {
    setBrandUrl(url);
    setHasErrorMessage(false);

    if (!isCreateMode) {
      const isUrlDifferentAsBefore = url !== selectedSecondTierItem?.brandUrl;
      urlInputHasChanged.current = isUrlDifferentAsBefore;
    }
  };

  const setEnableSaveButton = () => {
    if (!isCreateMode) {
      const shouldEnableSaveButton =
        titleNotEmpty.current && (titleInputHasChanged.current || urlInputHasChanged.current);
      setBrandValueChanges(shouldEnableSaveButton);
    }
  };

  const getInputPlaceHolder = () => {
    if (isCreateMode) {
      return translate('txtEnterYourBrandName');
    }
    return selectedSecondTierItem?.name;
  };

  return (
    <MainContentContainer>
      <WppCard>
        <BoxContent>
          <DSInput
            data-testid="input-brand-title"
            autoFocus
            name="title"
            labelConfig={{ text: translate('txtTitle') }}
            size="m"
            required
            placeholder={getInputPlaceHolder()}
            value={newBrandTitle}
            onWppChange={(event: CustomEvent) => {
              handleInputChange(event.detail.value);
              setEnableSaveButton();
            }}
          />
          <BrandFallbackInput
            data-testid="input-brand-website"
            autoFocus
            name="brand-website"
            labelConfig={{ text: translate('txtBrandFallbackTitle') }}
            size="m"
            placeholder={translate('txtBrandFallbackPlaceHolder')}
            value={brandUrl ?? ''}
            required
            onWppChange={(event: CustomEvent) => {
              handleBrandWebsiteChange(event.detail.value);
              setEnableSaveButton();
            }}
            message={hasErrorMessage ? translate('txtValidURL') : ''}
            messageType={hasErrorMessage ? 'error' : undefined}
          />
        </BoxContent>
      </WppCard>
      <BoxContent>
        <UpdatedCardInfo
          userEmail={selectedSecondTierItem?.updatedBy}
          createMode={isCreateMode}
          lastUpdated={(selectedSecondTierItem as SecondTierSidebarItem & { updatedAt: Date }).updatedAt}
        />
        <SendToCmsCard />
      </BoxContent>
    </MainContentContainer>
  );
};

export default TabDetails;
