import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import userEvent from '@testing-library/user-event';
import { ITheme } from '../../types';
import { render } from './test.utils';

const FakeTheme: ITheme = {
  applyDropShadow: false,
  palette: {
    dark: '#000',
    light: '#fff',
    primary: 'Lightblue',
    secondary: 'LightGrey',
    description: '#ffffff',
    primaryButtonBackground: '#ffffff',
    primaryButtonText: '#000000',
    secondaryButtonBackground: '#000000',
    secondaryButtonText: '#ffffff',
    textColor: '#ffffff',
    submitButtonBackground: '#000000',
    submitButtonText: '#ffffff',
    backgroundTopGradient: '#1e1e1e',
    backgroundMiddleGradient: '#1e1e1e',
    backgroundBottomGradient: '#1e1e1e',
  },
  typography: {},
  layout: {
    headerBreakPoints: {
      bp320: '25vh',
      bp480: '25vh',
      bp768: '30vh',
      bp960: '20vh',
    },
  },
};

export function renderWithProviders(component: any, theme: Partial<ITheme> = FakeTheme) {
  return render(
    <QueryClientProvider client={new QueryClient()}>
      <ThemeProvider theme={theme}>{component}</ThemeProvider>
    </QueryClientProvider>,
  );
}

export function renderWithEventSetup(component: any, theme: Partial<ITheme> = FakeTheme) {
  return {
    user: userEvent.setup(),
    ...render(
      <QueryClientProvider client={new QueryClient()}>
        <ThemeProvider theme={theme}>{component}</ThemeProvider>
      </QueryClientProvider>,
    ),
  };
}
