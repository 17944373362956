import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppCard, WppIconWarning, WppTypography } from '@wppopen/components-library-react';
import { DSInput } from '../../../../shared/DSComponents';

export const ContainerBare = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, calc(100% - 222px)) 222px',
  gridTemplateRows: '10vh 5vh 60vh',
  rowGap: 16,
  width: '100%',
  padding: '2.4rem',
  boxSizing: 'border-box',
});

export const ContainerOs = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, calc(100% - 222px)) 222px',
  gridTemplateRows: 'auto 5vh 60vh',
  rowGap: 16,
  width: '100%',
  padding: '2.4rem',
  boxSizing: 'border-box',
});

export const HeaderActionsContainer = styled.div({
  justifySelf: 'flex-end',
});

export const HeaderContainer = styled.div({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const TabsContainer = styled.div({
  width: 'fit-content',
});

export const MainContentTableContainer = styled.div({
  width: '100%',
  height: 'fit-content',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  display: 'grid',
  columnGap: '1.6rem',
  gridTemplateColumns: '3fr',
});

export const MainContentContainer = styled.div({
  width: '100%',
  height: 'fit-content',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  display: 'grid',
  columnGap: '1.6rem',
  gridTemplateColumns: '2fr 1fr',
});

export const MainContentInformationContainer = styled.div({
  boxSizing: 'border-box',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.8rem',
  height: '9.8rem',
  padding: '1.2rem 1.6rem',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
});

export const MainContentInformationTitleContainer = styled.div({
  gridColumnStart: 1,
  gridColumnEnd: 3,
});

export const WarningText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  '--wpp-typography-xs-body-font-size': '14px',
}));

export const IconWarning = styled(WppIconWarning)({ marginRight: '8px' });

export const BrandFallbackInput = styled(DSInput)({ marginTop: '24px' });

export const Card = styled(WppCard)({ marginTop: '1.2rem' });
