import { useState } from 'react';
import { WppActionButton, WppIconPlus } from '@wppopen/components-library-react';
import { StatusEnum } from '@connected-core-system/utils/types';
import WarningIconMessage from '../../../shared/components/WarningIconMessage/WarningIconMessage';
import { translate } from '../../../shared/locales';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { tableTypeEnum } from '../utils/contentStreamContentUtils';
import { tabContentValue } from '../utils/contentStreamCreateUtils';
import SelectedContentTable from './SelectedContentTable/SelectedContentTable';
import SelectedStoryDrawer from './SelectedStoryDrawer';
import { WppStyledCard } from './styles/SelectedStory.styled';
import { TitleText } from './styles/Targets.styled';

export interface ISelectedStoryProps {
  selectedStory: Partial<ContentsTableDataType>[];
  setSelectedStory: (selectedStory: Partial<ContentsTableDataType>[]) => void;
  isLoading: boolean;
  isInvalidContentTab: boolean;
}
const SelectedStory: React.FC<ISelectedStoryProps> = ({
  selectedStory,
  setSelectedStory,
  isLoading,
  isInvalidContentTab,
}) => {
  const [isSideModalOpen, setIsSideModalOpen] = useState<boolean>(false);
  const { selectedTab } = useSelectedTabState();

  const invalidStoryState = () => {
    return !selectedStory.length || selectedStory[0].status !== StatusEnum.ACTIVE;
  };

  const renderWarning = () => {
    return (
      isInvalidContentTab &&
      invalidStoryState() && (
        <div style={{ marginTop: selectedStory ? '24px' : '0px' }}>
          <WarningIconMessage message={translate('txtInvalidStoryWarning')} />
        </div>
      )
    );
  };

  return (
    <>
      <WppStyledCard>
        <TitleText type="m-strong" data-testid="title-stories-modal">
          {translate('txtSelectedStory')}
        </TitleText>
        {isLoading || selectedStory.length > 0 ? (
          <>
            <SelectedContentTable
              selectedItems={selectedStory}
              setSelectedItems={setSelectedStory}
              tableType={tableTypeEnum.SELECTED_STORY}
              isLoading={isLoading}
              forceReloadDimensions={selectedStory.length > 0 && selectedTab === tabContentValue}
            />
            {renderWarning()}
          </>
        ) : (
          <>
            {renderWarning()}
            <WppActionButton
              data-testid="add-story-button"
              style={{
                position: 'relative',
                marginTop: isInvalidContentTab && invalidStoryState() ? '30px' : '0px',
              }}
              disabled={isLoading}
              onClick={() => {
                setIsSideModalOpen(true);
              }}>
              <WppIconPlus slot="icon-start" />
              {translate('btnAdd', { something: translate('tabContent') })}
            </WppActionButton>
          </>
        )}
      </WppStyledCard>
      {isSideModalOpen && (
        <SelectedStoryDrawer
          selectedStory={selectedStory}
          setSelectedStory={setSelectedStory}
          isSideModalOpen={isSideModalOpen}
          setIsSideModalOpen={setIsSideModalOpen}
        />
      )}
    </>
  );
};

export default SelectedStory;
