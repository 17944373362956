import { WppActionButton, WppIconChevron, WppTypography } from '@wppopen/components-library-react';
import React from 'react';
import { BoxContent } from '../../../shared/components';
import { useHostName } from '../../../shared/hooks';
import { translate } from '../../../shared/locales/i18n';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { redirectToCMS } from '../utils/sendToCmsCardUtils';
import { Card, WarningText } from './styles/BrandPage.styled';

export interface ISendToCmsCardProps {}

const SendToCmsCard: React.FC<ISendToCmsCardProps> = () => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const baseHostName = useHostName();

  return (
    <Card data-testid="warning-cms-card">
      <BoxContent>
        <BoxContent flex>
          <WppTypography type="m-strong">{translate('txtConfigureCmsTitle')}</WppTypography>
        </BoxContent>
        <BoxContent flex marg="8 0">
          <WarningText type="xs-body">{translate('txtConfigureCms')}</WarningText>
        </BoxContent>
        <WppActionButton
          onClick={() => redirectToCMS(selectedOrganisation, baseHostName)}
          data-testid="go-to-cms-button">
          {translate('txtGoToCms')}
          <WppIconChevron direction="right" slot="icon-end" />
        </WppActionButton>
      </BoxContent>
    </Card>
  );
};

export default SendToCmsCard;
