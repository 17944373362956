import { ExceptionBase } from '@core/exceptions';
import { useMutation } from '@tanstack/react-query';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { verifyWebSiteLink } from '../utils/brandPageUtils';

import brandPageManager from '../services';

export const useBrandSendData = (
  onSuccessCreateCallback: (returnId: string | void) => void,
  onSuccessUpdateCallback: (returnId?: string) => void,
  onErrorCallback: (error: ExceptionBase) => void,
) => {
  const {
    isPending: isCreatePending,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    mutate: createBrand,
    data: createData,
  } = useMutation({
    mutationFn: ({
      organisationId,
      newBrandTitle,
      brandUrl,
    }: {
      organisationId: string;
      newBrandTitle: string;
      brandUrl: string | undefined;
    }) =>
      brandPageManager.createNewBrand(
        organisationId,
        newBrandTitle,
        brandUrl ? verifyWebSiteLink(brandUrl) : undefined,
      ),
    onSuccess: (data?: string | void) => {
      onSuccessCreateCallback(data);
    },
    onError: (error: ExceptionBase) => {
      onErrorCallback(error);
    },
  });

  const {
    isPending: isUpdatePending,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    mutate: updateBrand,
    data: updateData,
  } = useMutation({
    mutationFn: ({
      organisationId,
      secondTierItem,
      newBrandTitle,
      brandUrl,
    }: {
      organisationId: string;
      secondTierItem: SecondTierSidebarItem;
      newBrandTitle: string;
      brandUrl: string | undefined;
    }) =>
      brandPageManager.updateBrand(
        organisationId,
        { ...secondTierItem, name: newBrandTitle },
        brandUrl ? verifyWebSiteLink(brandUrl) : undefined,
      ),
    onSuccess: () => {
      onSuccessUpdateCallback();
    },
    onError: (error: ExceptionBase) => {
      onErrorCallback(error);
    },
  });

  return {
    isLoading: isCreatePending || isUpdatePending,
    isSuccess: isCreateSuccess || isUpdateSuccess,
    isError: isCreateError || isUpdateError,
    createBrand,
    updateBrand,
    data: createData || updateData,
  };
};
