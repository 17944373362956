import { StatusEnum } from '.';

export interface SecondTierSidebarItem {
  name: string;
  id: string;
  status: StatusEnum;
  createdBy?: string;
  updatedBy?: string;
  brandUrl?: string;
}
