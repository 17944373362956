import { waitFor as _waitFor, act, fireEvent, render, waitForOptions } from '@testing-library/react';

const waitFor = <T>(callback: () => T | Promise<T>, options?: waitForOptions): Promise<T> => {
  // Overwrite default options
  const mergedOptions = {
    ...options,
    timeout: 2000,
  };

  return _waitFor(callback, mergedOptions);
};

export * from '@testing-library/react';
export * from '@testing-library/user-event';
export { act, fireEvent, render, waitFor };
