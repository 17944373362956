import { ChangeEvent } from 'react';
import { DSInputProps } from '../DSInput/DSInput';

export const DSInputMock = ({
  onWppChange,
  ...props
}: {
  onWppChange?: (event: { detail: { value: string } }) => void;
} & DSInputProps) => {
  return (
    <>
      <input
        data-testid={`mock-input-${props.name}`}
        name={props.name}
        placeholder="mock-placeholder"
        value={props.value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onWppChange?.({ detail: { value: event.target.value } });
        }}
      />

      {props.message && <div>{props.message}</div>}
    </>
  );
};
