import { WppSpinner } from '@wppopen/components-library-react';
import { RowDragEvent } from 'ag-grid-community';
import { BaseTableDataType, BoxContentCenter, Table } from '../../../../shared/components';
import { reorderDataTableArray } from '../../../../shared/utils';
import { UNCLICKABLE_TABLE_IDS } from '../../../../shared/Constants/Constants';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';
import NameCell from '../ContentsTable/NameCell';
import TemplateCell from '../ContentsTable/TemplateCell';
import LanguageCell from '../ContentsTable/LanguagesCell';
import LastModifiedCell from '../ContentsTable/LastModifiedCell';
import { getColumnsContents, tableTypeEnum } from '../../utils/contentStreamContentUtils';
import OrderCell from './OrderCell';
import StatusCell from '../ContentsTable/StatusCell';
import DeleteCellStory from './DeleteCellStory';
import DeleteCellContent from './DeleteCellContent';

export interface ISelectedContentTableProps {
  tableType: tableTypeEnum;
  selectedItems: Partial<ContentsTableDataType>[];
  setSelectedItems: (selectedContent: Partial<ContentsTableDataType>[]) => void;
  isLoading: boolean;
  selectedContentToPreview?: Partial<ContentsTableDataType> | null;
  setSelectedContentToPreview?: (selectedContent: Partial<ContentsTableDataType>) => void;
  forceReloadDimensions?: boolean;
}

const SelectedContentTable: React.FC<ISelectedContentTableProps> = ({
  selectedItems,
  setSelectedItems,
  tableType,
  isLoading,
  selectedContentToPreview,
  setSelectedContentToPreview,
  forceReloadDimensions = false,
}) => {
  const columnsToExclude: Array<keyof ContentsTableDataType> =
    tableType === tableTypeEnum.SELECTED_STORY
      ? ['id', 'order', 'cmsId', 'deleteContent', 'status']
      : ['id', 'cmsId', 'deleteStory', 'status'];

  const contentsColumnDefinitions = getColumnsContents(columnsToExclude);

  const handleOnDrag = (row: RowDragEvent) => {
    const reorderedArray = reorderDataTableArray(selectedItems, row) as ContentsTableDataType[];
    setSelectedItems([...reorderedArray]);
  };

  if (contentsColumnDefinitions['order'])
    contentsColumnDefinitions['order'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <OrderCell order={data.order} />
    );

  contentsColumnDefinitions['name'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
    <NameCell name={data.name} />
  );

  contentsColumnDefinitions['updatedAt'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
    <LastModifiedCell updatedAt={data.updatedAt} />
  );

  if (contentsColumnDefinitions['status']) {
    contentsColumnDefinitions['status'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <StatusCell status={data.status} />
    );
  }

  if (contentsColumnDefinitions['collectionType']) {
    contentsColumnDefinitions['collectionType'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <TemplateCell collectionType={data.collectionType} />
    );
  }

  if (contentsColumnDefinitions['languages']) {
    contentsColumnDefinitions['languages'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <LanguageCell languages={data.languages} />
    );
  }

  if (contentsColumnDefinitions['deleteContent']) {
    contentsColumnDefinitions['deleteContent'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => {
      return <DeleteCellContent handleClick={handleDeleteContent} contentId={data.id} />;
    };
  }

  if (contentsColumnDefinitions['deleteStory']) {
    contentsColumnDefinitions['deleteStory'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => {
      return <DeleteCellStory handleClick={handleDeleteStory} />;
    };
  }

  const handleDeleteContent = (contentId: string) => {
    if (selectedItems.length > 1) {
      const indexOfRemovedElement = selectedItems.findIndex((content) => content.id === contentId);
      const contentsAfterRemoval = selectedItems.filter((c) => c.id !== contentId);
      reorderElements(indexOfRemovedElement, contentsAfterRemoval);
      setSelectedItems(contentsAfterRemoval);
    } else {
      setSelectedItems([]);
    }
  };

  const handleDeleteStory = () => {
    setSelectedItems([]);
  };

  const reorderElements = (indexOfRemovedElement: number, contentsAfterRemoval: Partial<ContentsTableDataType>[]) => {
    for (let index = indexOfRemovedElement; index < contentsAfterRemoval.length; index++) {
      if (contentsAfterRemoval[index].order)
        contentsAfterRemoval[index].order = (Number(contentsAfterRemoval[index].order ?? 0) - 1).toString();
    }
  };

  const defaultRowClickedHandler = (data: Partial<ContentsTableDataType> & BaseTableDataType) => {
    if (data) {
      setSelectedContentToPreview?.(data);
    }
  };

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="m" />
      </BoxContentCenter>
    );
  };

  const renderTable = () => {
    return (
      <Table
        displayData={selectedItems}
        columnDefs={Object.values({ ...contentsColumnDefinitions })}
        handleCellClicked={defaultRowClickedHandler}
        hasHeader={true}
        isLoading={isLoading}
        rowMultiSelectWithClick={false}
        renderLoading={renderLoading}
        selectedRowsId={selectedContentToPreview ? [selectedContentToPreview.id] : []}
        handleRowDrag={handleOnDrag}
        delayUseDimensions={forceReloadDimensions ? 0 : 100}
        columnsToPreventSelection={[
          {
            column: 'languages',
            elementsIds: [UNCLICKABLE_TABLE_IDS.languageMenuButton, UNCLICKABLE_TABLE_IDS.languageMenuIcon],
          },
        ]}
        selectionType="single"
      />
    );
  };

  return (
    <>
      {forceReloadDimensions && renderTable()}
      {!forceReloadDimensions && renderTable()}
    </>
  );
};

export default SelectedContentTable;
