/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppCard, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import React, { useCallback } from 'react';
import { formatTimeAgo } from '../../../../src/shared/utils';
import { BoxContent } from '../../../shared/components';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';
import { usePromise } from '../../../shared/hooks';
import { translate } from '../../../shared/locales';
import { useUserInfo } from '../../Authorization/hooks/useUserInfo';
import updatedInfoManager from '../services/UpdatedInfoManager';
import { LabelText, StyledWppTypographyOs, ValueText } from './styles/UpdatedCardInfo.styled';

export interface IUpdatedCardInfoProps {
  createMode?: boolean;
  userEmail?: string | null;
  lastUpdated?: Date | string;
}

const UpdatedCardInfo: React.FC<IUpdatedCardInfoProps> = ({ userEmail, createMode, lastUpdated }) => {
  const { userInfo } = useUserInfo();
  const loadUserDetails = useCallback(() => {
    if (userEmail && !createMode) {
      return updatedInfoManager.getUserDetails(userEmail);
    }
    return null;
  }, [userEmail, createMode]);

  const { data: userDetails, loading } = usePromise(loadUserDetails);

  const renderName = () => {
    if (createMode) {
      return <ValueText type="xs-body">{userInfo?.name ?? ' - '}</ValueText>;
    }

    if (!createMode && userEmail) {
      if (loading) {
        return <WppSpinner size="s" />;
      }
      return <ValueText type="xs-body">{userDetails?.name ?? ' - '}</ValueText>;
    }
    return null;
  };

  const lastUpdatedValue = lastUpdated ? formatTimeAgo(lastUpdated, new Date()) : ' - ';
  return (
    <WppCard>
      <BoxContent>
        <PlatformWrapper componentBare={WppTypography} componentOs={StyledWppTypographyOs} type="m-strong">
          {translate('txtInformation')}
        </PlatformWrapper>
        <BoxContent flex justify="space-between" marg="0 0 4 0">
          <LabelText type="xs-body">{translate('txtLastUpdate')}</LabelText>
          <ValueText type="xs-body">{createMode ? ' - ' : lastUpdatedValue}</ValueText>
        </BoxContent>
        <BoxContent flex justify="space-between">
          <LabelText type="xs-body" color={'red'}>
            {translate('txtBy')}
          </LabelText>
          {renderName()}
        </BoxContent>
      </BoxContent>
    </WppCard>
  );
};

export default UpdatedCardInfo;
